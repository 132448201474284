import React from "react";

/** BOOTSTRAP */
import { Nav, Tab } from 'react-bootstrap';

/** Componentes */
import { useAuth } from "../components/authprovider.component";
import NavbarMenu from "../components/navbar.component";

const BoardADC = () => {
    const auth = useAuth();

    return (
        <div>
            <NavbarMenu auth={auth} />
            <div className="container fondo_general">
                <header className="bg-light p-4 mt-3">
                    <h3 className="text-center">Sección de Reportes para Lideres Ecora</h3>
                </header>
                <br />
                <Tab.Container>
                    <table className="table">
                        <tr>
                            {/* <td><Nav.Link className="text-center" href="/reporterrhh/1/ReportSection52db391b319f618b7a7c" eventKey="#link3" aria-describedby="reporte_tarea"><img src="/img/iconos/detalle.png" alt="Reporte de Tareas" width="100" height="100" /></Nav.Link><div id="reporte_tarea" className="form-text text-center">Reporte General</div></td> */}
                            <td><Nav.Link className="text-center" href="/reporterrhh/10/ReportSection28ebe681c113a0688240" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Personal Activo</div></td>
                            <td><Nav.Link className="text-center" href="/reporterrhh/11/ReportSectiond9b25dfe2d88135063f0" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Licencia</div></td>
                            <td><Nav.Link className="text-center" href="/reporterrhh/12/ReportSection540ccd372b22f19e9247" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Vacaciones</div></td>
                        </tr>
                        <tr>
                            <td><Nav.Link className="text-center" href="/reportereclu/14/ReportSection9e6205d7b629381d0d48" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Estado DNC por Unidad de Negocio</div></td>
                            <td><Nav.Link className="text-center" href="/reportereclu/14/e2565cfc1c499a893786" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Estado DNC por Unidad de Negocio Versión 2</div></td>
                            <td><Nav.Link className="text-center" href="/reportereclu/14/ReportSection7fc3634554e7d92ad67a" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Estado DNC por Cargo</div></td>
                        </tr>
                        <tr>
                            <td><Nav.Link className="text-center" href="/reportereclu/14/ReportSection8ea94dd45fd71d077e72" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Tiempos Por Estado</div></td>
                            <td><Nav.Link className="text-center" href="/reporterrhh/14/ReportSectiondff4173d2d5ebc7cd285" aria-describedby="reporte_hora"><img src="/img/iconos/cliente.png" alt="Reporte de Cliente Proyectos Menores" width="100" height="100" /></Nav.Link><div id="´reporte_horas" className="form-text text-center">Documentos Vencidos/Por Vencer</div></td>
                        </tr>
                        <tr>
                            <td><Nav.Link className="text-center" href="/reportecompras/1/ReportSection480ebcde90d2e8999500" eventKey="#link3" aria-describedby="reporte_tarea"><img src="/img/iconos/detalle.png" alt="Reporte de Tareas" width="100" height="100" /></Nav.Link><div id="reporte_tarea" className="form-text text-center">Reporte Pedidos Pendientes</div></td>
                            <td><Nav.Link className="text-center" href="/reportecompras/2/ReportSectioncdb44c43f0f54b074ae0" aria-describedby="reporte_rendimiento"><img src="/img/iconos/rendimiento.png" alt="Reporte de Tiempos Compras Locales" width="100" height="100" /></Nav.Link><div id="reporte_rendimiento" className="form-text text-center">Reporte Tiempos Compras Locales</div></td>
                            <td><Nav.Link className="text-center" href="/reportecompras/2/a0d579aef2647574cf44" aria-describedby="reporte_rendimiento"><img src="/img/iconos/rendimiento.png" alt="Reporte de Tiempos Compras Nacionales" width="100" height="100" /></Nav.Link><div id="reporte_rendimiento" className="form-text text-center">Reporte Tiempos Compras Nacionales</div></td>
                        </tr>
                    </table>
                </Tab.Container>
            </div>
        </div>
    );
}

export default BoardADC;